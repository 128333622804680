/**
 * This middleware is used to check if the organization exists before try to go to the dedicated page.
 * If the organization exists, we forwarding to a store the linked data to avoid a second Algolia call.
 * If the organizations not exists, we redirecting with the wanted HTTP code to the search page.
 */

import {
  useNuxtApp,
  defineNuxtRouteMiddleware,
  useRequestEvent
} from '#imports'
import { setHeader } from 'h3'
import { callWithNuxt, createError, useAsyncData } from 'nuxt/app'
import { useFormsStore } from '@/store/forms.store'
import { useOrganizationStore } from '@/store/organization.store'

export default defineNuxtRouteMiddleware(async to => {
  // To understand how works this middleware redirection,
  // Read this : https://github.com/nuxt/nuxt/issues/14269

  const nuxtApp = useNuxtApp()

  const organizationStore = useOrganizationStore()

  const { organization } = to.params

  const response = await useAsyncData('fetchOrganizationPageResponse', () => {
    return organizationStore.fetchOrganization(Array.isArray(organization) ? organization[0] : organization)
  })
  
  if (response.status.value === 'error') {
    throw createError({ status: 404 })
  } else {
    const updateDate = (response.data.value as any)?.updateDate

    if (import.meta.server && updateDate) {
      setHeader(
        useRequestEvent(),
        'last-modified',
        new Date(updateDate).toUTCString()
      )
    }

    // We must link the currentNuxtApp to resolve the nuxt context because
    // we are in a middleware, so we don't have the current nuxt context.
    await callWithNuxt(nuxtApp, async () => {
      const formsStore = useFormsStore()

      const organizationKey = Array.isArray(organization)
        ? organization[0]
        : organization

      return await formsStore.fetchForms(organizationKey)
    })
  }
})
